import { gql } from '@/__generated__';

// Global fragments

export const SOCIAL_LINKS_FRAGMENT = gql(`
  fragment socialLinks on socialLinks_links_BlockType {
    __typename
    instagram
    linkedin
  }
`);

export const ORGANISATIONS_FRAGMENT = gql(`
  fragment organisations on organisations_organisation_BlockType {
    __typename
    id
    linkField {
      ...linkField
    }
    logo {
      ...image
    }
  }
`);

// Document fragments

export const DOCUMENT_BASE_FRAGMENT = gql(`
  fragment documentBase on AssetInterface {
    mimeType
    id
    alt
    title
  }
`);

export const DOCUMENT_FRAGMENT = gql(`
  fragment document on AssetInterface {
    ...documentBase
    __typename
    url
    filename
    extension
    size
  }
`);
