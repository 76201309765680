import { gql } from '@/__generated__';

export const ENTRY_BASE_FRAGMENT = gql(`
  fragment entryBase on EntryInterface {
    __typename
    typeHandle
    searchScore
    id
    uri
    url
    slug
    title
    sectionHandle
    postDate @formatDateTime(format: "j F Y")
    parent {
        id
        uri
        title
    }
  }
`);

export const ARTICLE_INDEX_CARD_FRAGMENT = gql(`
  fragment articleIndexCard on articleIndex_articleIndex_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }  
`);

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment articleCard on article_default_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
    contentTypeCategorySingle {
      ...categories
    }
  }
`);

export const CAREERS_CARD_FRAGMENT = gql(`
  fragment careersCard on careers_careers_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
    jobPositionMultiple {
      ...jobPositionCard
    }
  }
`);

export const COLLABORATOR_CARD_FRAGMENT = gql(`
  fragment collaboratorCard on collaborator_default_Entry {
    ...entryBase
    entrySingle {
      ...pageEntryCards
    }

    # Override props
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
    linkField {
      ...linkField
    }
  }
`);

// Only used for the community index fragment
export const COMMUNITY_CARD_FRAGMENT = gql(`
  fragment communityCard on collaborator_default_Entry {
    ...entryBase
    entrySingle {
      ...articleIndexCard
      ...careersCard
      ...contactCard
      ...homeCard
      ...pageStandardCard
      ...pageOverviewCard
    }

    # Override props
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
    linkField {
      ...linkField
    }
  }
`);

export const COMMUNITY_INDEX_CARD_FRAGMENT = gql(`
  fragment communityIndexCard on communityIndex_communityIndex_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
    collaboratorMultiple {
      ...communityCard
    }
  }
`);

export const CONTACT_CARD_FRAGMENT = gql(`
  fragment contactCard on contact_contact_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

export const HOME_CARD_FRAGMENT = gql(`
  fragment homeCard on home_home_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

export const JOB_POSITION_CARD_FRAGMENT = gql(`
  fragment jobPositionCard on jobPosition_default_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    linkField {
      ...linkField
    }
  }
`);

export const INSTAGRAM_POST_CARD_FRAGMENT = gql(`
  fragment instagramPostCard on instagramPost_default_Entry {
    ...entryBase
    entryImage {
      ...image
    }
    imageSingle {
      ...image
    }
    caption
  }
`);

export const PAGE_STANDARD_CARD_FRAGMENT = gql(`
  fragment pageStandardCard on page_default_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

export const PAGE_OVERVIEW_CARD_FRAGMENT = gql(`
  fragment pageOverviewCard on page_overview_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

export const PROFILE_CARD_FRAGMENT = gql(`
  fragment profileCard on profile_default_Entry {
    ...entryBase
    firstName
    lastName
    label
  }
`);

export const SEARCH_CARD_FRAGMENT = gql(`
  fragment searchCard on search_search_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

export const NOT_FOUND_CARD_FRAGMENT = gql(`
  fragment notFoundCard on notFound_notFound_Entry {
    ...entryBase
    pageTitle
    summary: entrySummary
    entryImage {
      ...image
    }
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Cards ----

export const PAGE_ENTRY_CARDS_FRAGMENT = gql(`
  fragment pageEntryCards on EntryInterface {
    ...articleIndexCard
    ...careersCard
    ...communityIndexCard
    ...contactCard
    ...homeCard
    ...pageStandardCard
    ...pageOverviewCard
  }
`);

export const ENTRY_CARDS_FRAGMENT = gql(`
  fragment entryCards on EntryInterface {
    ...articleIndexCard
    ...articleCard
    ...careersCard
    ...communityIndexCard
    ...contactCard
    ...homeCard
    ...pageStandardCard
    ...pageOverviewCard
    ...searchCard
    ...jobPositionCard
    ...profileCard
    ...collaboratorCard
    ...instagramPostCard
    ...notFoundCard
  }
`);
