import { gql } from '@/__generated__';

export const CATEGORY_ID_FRAGMENT = gql(`
  fragment categoryId on CategoryInterface {
    __typename
    id
    title
    parent {
      id
    }
  }
`);

export const CATEGORY_BASE_FRAGMENT = gql(`
  fragment categoryBase on CategoryInterface {
    __typename
    id
    uri
    slug
    title
    groupHandle
  }
`);

export const CONTENT_TYPE_CATEGORY_FRAGMENT = gql(`
  fragment contentTypeCategory on contentTypeCategory_Category {
    ...categoryBase
    title
  }
`);

export const CATEGORIES_FRAGMENT = gql(`
  fragment categories on CategoryInterface {
    ...contentTypeCategory
  }
`);
