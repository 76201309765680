import * as ENTRIES_FRAGMENTS from '@/gql/fragments/entries.gql';
import * as ENTRY_CARDS_FRAGMENTS from '@/gql/fragments/entryCards.gql';
import * as BLOCK_FRAGMENTS from '@/gql/fragments/blocks.gql';
import * as LINK_FRAGMENTS from '@/gql/fragments/links.gql';
import * as COMMON_FRAGMENTS from '@/gql/fragments/common.gql';
import * as SEO_FRAGMENTS from '@/gql/fragments/seo.gql';
import * as CATEGORIES_FRAGMENTS from '@/gql/fragments/categories.gql';
import * as NAVIGATION_FRAGMENTS from '@/gql/fragments/navigation.gql';
import * as IMAGE_FRAGMENTS from '@/gql/fragments/images.gql';
import * as FORM_FRAGMENTS from '@/gql/fragments/form.gql';

import { createFragmentRegistry } from '@apollo/client/cache';
import { DocumentNode } from 'graphql';

const typedFragments: DocumentNode[] = [
  ...Object.values(ENTRY_CARDS_FRAGMENTS),
  ...Object.values(ENTRIES_FRAGMENTS),
  ...Object.values(BLOCK_FRAGMENTS),
  ...Object.values(COMMON_FRAGMENTS),
  ...Object.values(LINK_FRAGMENTS),
  ...Object.values(SEO_FRAGMENTS),
  ...Object.values(CATEGORIES_FRAGMENTS),
  ...Object.values(NAVIGATION_FRAGMENTS),
  ...Object.values(IMAGE_FRAGMENTS),
  ...Object.values(FORM_FRAGMENTS),
];

export const fragmentRegistry = createFragmentRegistry(...typedFragments);

export const registerFragment = <T extends DocumentNode>(fragment: T | T[]) => {
  const fragments = Array.isArray(fragment) ? fragment : [fragment];
  fragmentRegistry.register(...fragments);
};
