import { gql } from '@/__generated__';

export const RICH_TEXT_BLOCK_FRAGMENT = gql(`
  fragment richTextBlock on blocks_richText_BlockType {
    id
    __typename
    level
    content: htmlContentDefault
    linkField {
      ...linkField
    }
    # Customisation props
    backgroundColorOpacity
  }
`);

export const TWO_COLUMN_TEXT_BLOCK_FRAGMENT = gql(`
  fragment twoColumnTextBlock on blocks_twoColumnText_BlockType {
    id
    __typename
    level
    largeContent: htmlContentSimple
    smallContent: htmlContentDefault
    linkField {
      ...linkField
    }

    # Customisation props
    flipAlignment
    backgroundColorOpacity
  }
`);

export const ANNOUNCEMENT_BLOCK_FRAGMENT = gql(`
  fragment announcementBlock on blocks_announcement_BlockType {
    id
    __typename
    level
    heading
    content: htmlContentDefault

    # Customisation props
    backgroundColorOpacity
  }
`);

export const QUOTE_BLOCK_FRAGMENT = gql(`
  fragment quoteBlock on blocks_quote_BlockType {
    id
    __typename
    level
    content: htmlContentSimple
    author: label

    # Customisation props
    backgroundColorOpacity
    blockPosition
  }
`);

export const SOCIAL_MEDIA_BLOCK_FRAGMENT = gql(`
  fragment socialMediaBlock on blocks_socialMedia_BlockType {
    id
    __typename
    level
    heading
    instagramPostMultiple {
      ...instagramPostCard
    }
  }
`);

export const IMAGE_BLOCK_FRAGMENT = gql(`
  fragment imageBlock on blocks_image_BlockType {
    id
    __typename
    level
    imageSingle {
      ...image
    }
    caption

    # Customisation props
    backgroundColorOpacity
  }
`);

export const VIDEO_BLOCK_FRAGMENT = gql(`
  fragment videoBlock on blocks_video_BlockType {
    id
    __typename
    level
    videoUrl: externalUrl
    caption
    content: htmlContentSimple
    # videoTextTracksSingle

    # Customisation props
    backgroundColorOpacity
    blockWidth
  }
`);

export const IMAGE_CAROUSEL_BLOCK_FRAGMENT = gql(`
  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {
    id
    __typename
    level
    children {
      ...imageBlock
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

export const IMAGE_GALLERY_BLOCK_FRAGMENT = gql(`
  fragment imageGalleryBlock on blocks_imageGallery_BlockType {
    id
    __typename
    level
    imageSingle {
      ...image
    }
    imageWidth
    caption
  }
`);

export const MEDIA_GALLERY_BLOCK_FRAGMENT = gql(`
  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {
    id
    __typename
    level
    children {
      ...imageGalleryBlock
    }

    # Customisation props
    backgroundColorOpacity
    blockWidth
  }
`);

export const MEDIA_DOWNLOAD_BLOCK_FRAGMENT = gql(`
  fragment mediaDownloadBlock on blocks_mediaDownload_BlockType {
    id
    __typename
    level
    documentMultiple {
      ...document
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

export const MAP_BLOCK_FRAGMENT = gql(`
  fragment mapBlock on blocks_map_BlockType {
    id
    __typename
    level
    map {
      lat
      lng
      address
      parts {
        address
        city
        country
        county
        number
        postcode
        state
      }
    }
  }
`);

export const INTERACTIVE_MAP_BLOCK_FRAGMENT = gql(`
  fragment interactiveMapBlock on blocks_interactiveMap_BlockType {
    id
    __typename
    level
    heading
  }
`);

// --------------------------------------------------------------------------------------------------
// ---- Form fragments ----

export const SUBSCRIBE_FORM_BLOCK_FRAGMENT = gql(`
  fragment subscribeFormBlock on blocks_subscribeForm_BlockType {
    id
    __typename
    level
    __typename
    level
    heading
    content: htmlContentSimple

    # Customisation props
    backgroundColorOpacity
  }
`);

export const FORM_BLOCK_FRAGMENT = gql(`
  fragment formBlock on blocks_form_BlockType {
    id
    __typename
    level
    heading
    content: htmlContentSimple
    form {
      ...formieForm
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Card fragments ----

export const EXTERNAL_CARD_BLOCK_FRAGMENT = gql(`
  fragment externalCardBlock on blocks_externalCard_BlockType {
    id
    __typename
    level
    heading
    content: htmlContentSimple
    imageSingle {
      ...image
    }
    linkField {
      ...linkField
    }
  }
`);

export const ENTRY_CARD_BLOCK_FRAGMENT = gql(`
  fragment entryCardBlock on blocks_entryCard_BlockType {
    id
    __typename
    level
    entrySingle {
      ...pageEntryCards
    }

    # Override props
    heading
    content: htmlContentSimple
    imageSingle {
      ...image
    }
  }
`);

export const LATEST_ARTICLES_BLOCK_FRAGMENT = gql(`
  fragment latestArticlesBlock on blocks_latestArticles_BlockType {
    id
    __typename
    level
    heading

    # Override props
    articleSingle {
      ...articleCard
    }
    articleMultiple {
      ...articleCard
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

export const ENTRY_CARDS_BLOCK_FRAGMENT = gql(`
  fragment entryCardsBlock on blocks_entryCards_BlockType {
    id
    __typename
    level
    children {
      ...entryCardBlock
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

export const COLLABORATOR_CARDS_BLOCK_FRAGMENT = gql(`
  fragment collaboratorCardsBlock on blocks_collaboratorCards_BlockType {
    id
    __typename
    level
    heading
    content: htmlContentSimple
    collaboratorMultiple {
      ...collaboratorCard
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

export const PROFILE_CARDS_BLOCK_FRAGMENT = gql(`
  fragment profileCardsBlock on blocks_profileCards_BlockType {
    id
    __typename
    level
    heading
    profileMultiple {
      ...profileCard
    }

    # Customisation props
    backgroundColorOpacity
  }
`);

// --------------------------------------------------------------------------------------------------
// ---- Put it all together ----

export const BLOCKS_FRAGMENT = gql(`
  fragment blocks on blocks_NeoField {
    ...richTextBlock
    ...twoColumnTextBlock
    ...announcementBlock
    ...quoteBlock
    ...socialMediaBlock
    ...imageBlock
    ...videoBlock
    ...imageCarouselBlock
    ...imageGalleryBlock
    ...mediaGalleryBlock
    ...mediaDownloadBlock
    ...mapBlock
    ...interactiveMapBlock

    # Forms
    ...subscribeFormBlock
    ...formBlock

    # Cards
    ...externalCardBlock
    ...entryCardBlock
    ...latestArticlesBlock
    ...entryCardsBlock
    ...collaboratorCardsBlock
    ...profileCardsBlock
  }
`);
