import { create } from 'zustand';

export type PopupState = {
  modal: boolean;
  setModal: (open: boolean) => void;
};

export const usePopupState = create<PopupState>()((set) => ({
  modal: false,
  setModal: (modal?: boolean) => set(() => ({ modal })),
}));
