'use client';

import { DEFAULT_TIMEOUT } from '@/lib/constants';
import { useGlobal } from '@/lib/getters/useGlobal';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './PagePopup.css';
import { usePopupState } from './usePopupState';

export default function PagePopup() {
  const state = usePopupState((s) => s);
  const [value, setValue] = useLocalStorage('popup-state', true);
  const t = useTranslations('common');

  const acknowledgement = useGlobal(
    { handle: 'acknowledgementOfCountry' },
    'acknowledgementOfCountry_GlobalSet'
  );
  const { content } = acknowledgement ?? {};

  useEffect(() => {
    const timeout = setTimeout(() => {
      state.setModal(true);
    }, DEFAULT_TIMEOUT);

    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const show = value && state.modal;

  const handleClick = () => {
    setValue(false);
    state.setModal(false);
  };

  return (
    <AnimatePresence mode="wait">
      {show && (
        <Flex
          key="popup-modal"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          alignItems="center"
          justifyContent="center"
          className={styles.root}
          colorTheme="dark"
          colorSet="full"
          paper>
          <Grid rowGutter>
            <Grid.Col offsetMd={3} md={6}>
              <Txt as="h4" variant="h4" html textAlign="center">
                {content ?? t('acknowledgementOfCountry')}
              </Txt>
            </Grid.Col>
            <Grid.Col cx={{ display: 'flex', direction: 'row', justifyContent: 'center' }}>
              <Btn
                aria-label="close-popup"
                variant="icon"
                color="accent"
                onClick={() => handleClick()}
                EndIconProps={{ name: 'close', cx: { fontSize: 'h3' } }}
              />
            </Grid.Col>
          </Grid>
        </Flex>
      )}
    </AnimatePresence>
  );
}
