'use client';

import * as style from './TestGrid.css';

import useViewportWidth from '@/lib/utils/useViewportWidth';
import Box from '@/components/ui/Box';
import Container from '@/components/ui/Container';
import Grid from '@/components/ui/Grid';


/**
 * Grid overlay or dev purposes
 */
const TestGrid = () => {
  const [breakpointMin] = useViewportWidth();
  const nCols = breakpointMin('md') ? 12 : 6;

  const items = Array(nCols).fill('');

  return (
    <Box className={style.root}>
      <Container className={style.gridContainer}>
        <Grid columns={nCols}>
          {items.map((v, i) => (
            <Grid.Col xs={1} key={i}>
              <Box className={style.gridItemInner} />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TestGrid;
