'use client';

import { vars } from '@/theme/vars.css';
import { createTheme } from '@mui/material';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import React, { PropsWithChildren } from 'react';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

export type ThemeProviderProps = PropsWithChildren<{
  mode?: 'light' | 'dark';
}>;

const defaultMode = 'dark'; // darkMode ? 'dark' : 'light';

const theme = createTheme({
  typography: {
    fontFamily: vars.typography.font.body,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    mode: defaultMode,
  },
  components: {},
});

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>{children}</React.Fragment>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
