import { gql } from '@/__generated__';

export const ENTRY_ID_FRAGMENT = gql(`
  fragment entryId on EntryInterface {
    __typename
    id
    title
    parent {
      id
    }
  }
`);

export const ARTICLE_INDEX_ENTRY_FRAGMENT = gql(`
  fragment articleIndexEntry on articleIndex_articleIndex_Entry {
    # ...entrySeo
    ...articleIndexCard
  }
`);

export const ARTICLE_ENTRY_FRAGMENT = gql(`
  fragment articleEntry on article_default_Entry {
    # ...entrySeo
    ...articleCard
    blocks {
      ...blocks
    }
  }
`);

export const CAREERS_ENTRY_FRAGMENT = gql(`
  fragment careersEntry on careers_careers_Entry {
    # ...entrySeo
    ...careersCard
    content: htmlContentSimple
  }
`);

export const COLLABORATOR_ENTRY_FRAGMENT = gql(`
  fragment collaboratorEntry on collaborator_default_Entry {
    # ...entrySeo
    ...collaboratorCard
  }
`);

export const COMMUNITY_INDEX_ENTRY_FRAGMENT = gql(`
  fragment communityIndexEntry on communityIndex_communityIndex_Entry {
    # ...entrySeo
    ...communityIndexCard
  }
`);

export const CONTACT_ENTRY_FRAGMENT = gql(`
  fragment contactEntry on contact_contact_Entry {
    # ...entrySeo
    ...contactCard
    content: htmlContentDefault
    form {
      ...formieForm
    }
    heading
    label
  }
`);

export const HOME_ENTRY_FRAGMENT = gql(`
  fragment homeEntry on home_home_Entry {
    # ...entrySeo
    ...homeCard
    heading
    videoUrl
    blocks {
      ...blocks
    }
  }
`);

export const JOB_POSITION_ENTRY_FRAGMENT = gql(`
  fragment jobPositionEntry on jobPosition_default_Entry {
    ...jobPositionCard
  }
`);

export const INSTAGRAM_POST_ENTRY_FRAGMENT = gql(`
  fragment instagramPostEntry on instagramPost_default_Entry {
    ...instagramPostCard
  }
`);

export const PAGE_STANDARD_ENTRY_FRAGMENT = gql(`
  fragment pageStandardEntry on page_default_Entry {
    # ...entrySeo
    ...pageStandardCard
    blocks {
      ...blocks
    }
  }
`);

export const PAGE_OVERVIEW_ENTRY_FRAGMENT = gql(`
  fragment pageOverviewEntry on page_overview_Entry {
    # ...entrySeo
    ...pageOverviewCard
    videoUrl
    blocks {
      ...blocks
    }
  }
`);

export const PROFILE_ENTRY_FRAGMENT = gql(`
  fragment profileEntry on profile_default_Entry {
    ...profileCard
  }
`);

export const SEARCH_ENTRY_FRAGMENT = gql(`
  fragment searchEntry on search_search_Entry {
    # ...entrySeo
    ...searchCard
  }
`);

export const NOT_FOUND_ENTRY_FRAGMENT = gql(`
  fragment notFoundEntry on notFound_notFound_Entry {
    # ...entrySeo
    ...notFoundCard
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Entries fragment ----

export const ENTRIES_FRAGMENT = gql(`
  fragment entries on EntryInterface {
    ...articleIndexEntry
    ...articleEntry
    ...careersEntry
    ...communityIndexEntry
    ...contactEntry
    ...homeEntry
    ...pageStandardEntry
    ...pageOverviewEntry
    ...searchEntry
    ...collaboratorEntry
    ...jobPositionEntry
    ...profileEntry
    ...instagramPostEntry
    ...notFoundEntry
  }
`);

export const PAGE_ENTRIES_FRAGMENT = gql(`
  fragment pageEntries on EntryInterface {
    ...articleIndexEntry
    ...articleEntry
    ...careersEntry
    ...communityIndexEntry
    ...contactEntry
    ...homeEntry
    ...pageStandardEntry
    ...pageOverviewEntry
    ...searchEntry
    ...notFoundEntry
  }
`);
