import { gql } from '@/__generated__';

export const NAVIGATION_NODE_FRAGMENT = gql(`
  fragment navigationNode on NodeInterface {
    __typename
    id
    uri
    url
    level
    title
    newWindow
    typeLabel
    classes
    parent {
      id
    }
    element {
      uri
      language
    }
  }
`);
