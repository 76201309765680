import { gql } from '@/__generated__';

export const IMAGE_SIZE_ORIGINAL_FRAGMENT = gql(`
  fragment imageSize_original on AssetInterface {
    url @transform(transform: "fullFit")
    height @transform(transform: "fullFit")
    width @transform(transform: "fullFit")
    original_url: url
    original_height: width
    original_width: height
  }
`);

export const IMAGE_SIZE_FULL_FIT_FRAGMENT = gql(`
  fragment imageSize_fullFit on AssetInterface {
    fullFit_url: url
    fullFit_height: width
    fullFit_width: height
  }
`);

export const IMAGE_SIZE_ALL_FRAGMENT = gql(`
  fragment imageSize_all on AssetInterface {
    ...imageSize_original
    ...imageSize_fullFit
  }
`);

export const IMAGE_SIZE_COMMON_FRAGMENT = gql(`
  fragment imageSize_common on AssetInterface {
    ...imageSize_original
    ...imageSize_fullFit
  }
`);

export const IMAGE_BASE_FRAGMENT = gql(`
  fragment imageBase on AssetInterface {
    mimeType
    id
    alt
    title
  }
`);

export const IMAGE_FRAGMENT = gql(`
  fragment image on AssetInterface {
    ...imageBase
    ...imageSize_common
  }
`);
