import { fragmentRegistry } from '@/gql/fragments';
import possibleTypes from '@/possibleTypes.json';
import { InMemoryCacheConfig, TypePolicies } from '@apollo/client';

const typePolicies: TypePolicies = {};

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies,
  fragments: fragmentRegistry,
  possibleTypes,
};
