'use client';

import { QUERY_FORWARD_ROUTE } from '@/lib/constants';
import { ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { cacheConfig } from './cache';

/**
 * @see https://www.apollographql.com/blog/announcement/frontend/using-apollo-client-with-next-js-13-releasing-an-official-library-to-support-the-app-router/
 */
function makeClient(initialState?: NormalizedCacheObject) {
  const httpLink = new BatchHttpLink({
    uri: QUERY_FORWARD_ROUTE,
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 20, // Wait no more than 20ms after the last operation
  });

  initialState =
    initialState ??
    (typeof window !== 'undefined' && '__APOLLO_STATE__' in window
      ? (window.__APOLLO_STATE__ as NormalizedCacheObject)
      : {});

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(cacheConfig).restore(initialState),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
  });
}

type ApolloWrapperProps = React.PropsWithChildren<{
  initialState?: NormalizedCacheObject;
}>;

export default function ApolloWrapper({ children, initialState }: ApolloWrapperProps) {
  return (
    <ApolloNextAppProvider makeClient={() => makeClient(initialState)}>
      {children}
    </ApolloNextAppProvider>
  );
}
