import { gql } from '@/__generated__';

export const FORM_FIELD_FRAGMENT = gql(`
    fragment formieField on FieldInterface {
    ...fieldAddress
    ...fieldAgree
    ...fieldDropdown
    ...fieldSingleLineText
    ...fieldMultiLineText
    ...fieldNumber
    ...fieldPhone
    ...fieldDate
    ...fieldEmail
    ...fieldCheckboxes
    ...fieldRadio
    ...fieldName
    }
`);

export const FORMIE_FORM_FRAGMENT = gql(`
    fragment formieForm on FormInterface {
    __typename
    id
    handle
    submissionMutationName
    slug
    formFields {
        ...formieField
    }
    settings {
        submitActionMessageHtml
        errorMessageHtml
    }
    rows {
        id
        rowFields {
        id
        }
    }
    }
`);
